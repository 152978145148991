import {
    // AIQUOTE_UPDATE_LOG,
    AIQUOTE_AREA_LIST_SUCCESS,
    AIQUOTE_UPDATE_LOG_SUCCESS,

    // AIQUOTE_STARTED_PAGE_INIT,
    AIQUOTE_STARTED_PAGE_SUCCESS,
    AIQUOTE_STARTED_PAGE_ERROR,

    AIQUOTE_LIST_INIT,
    AIQUOTE_LIST_ERROR,
    AIQUOTE_LIST_SUCCESS,

    AIQUOTE_ACCOUNT_DETAIL_SUCCESS,
    AIQUOTE_ACCOUNT_DETAIL_ERROR,

    // AIQUOTE_SITE_UPDATE_INIT,
    AIQUOTE_SITE_UPDATE_ERROR,
    AIQUOTE_SITE_UPDATE_SUCCESS,

    // AIQUOTE_SITE_STAGE_INIT,
    AIQUOTE_SITE_STAGE_ERROR,
    AIQUOTE_SITE_STAGE_SUCCESS,

    AIQUOTE_DELETE_ERROR,
    AIQUOTE_DELETE_SUCCESS,
    AIQUOTE_START_ERROR,
    AIQUOTE_START_SUCCESS,
    RELEASE_STATE_DATA,
    // AIQUOTE_AUTOSELECT_SAVE,
    AIQUOTE_AUTOSELECT_SAVE_SUCCESS,
    AIQUOTE_CHECK_SALESFORCE_SUCCESS,
    // AIQUOTE_CHECK_EMAIL_INIT,
    AIQUOTE_EMAIL_REQUESTED_INIT,
    AIQUOTE_AUTOSELECT_AUTOMATION_SAVE_SUCCESS,
    AIQUOTE_ACCOUNT_DETAIL_AUTOMATION_SUCCESS,
    AIQUOTE_ACCOUNT_DETAIL_AUTOMATION_ERROR,
    AIQUOTE_DELETE_EMAIL_SUCCESS,
    AIQUOTE_DELETE_EMAIL_ERROR,
    // AIQUOTE_AUTOSELECT_FOR_LAUNCH_SAVE_SUCCESS,
    AIQUOTE_AUTOSELECT_FOR_LAUNCH_SAVE,
    AIQUOTE_CHECK_SERVER_SUCCESS,
    AIQUOTE_AUTOMATION_STATUS_SUCCESS,
    AIQUOTE_AUTOMATION_STATUS_CHANGE_SUCCESS
} from './actions';
import { combineReducers } from "redux";
// import  manageAIQUOTEReducer  from './ManageAIQUOTE/reducer';
// import  aiquotePadReducer  from './AIQUOTEPad/reducer';

// The initial state of the Login Reducer
export const initialState = {
    successful: false,
    messages: [],
    errors: [],
    aiquotes: [],
    aiquoteSites: [],
    aiquoteSitesOnServer: [],
    aiquoteAutoSelect: {},
    aiquoteAutoSelectForLaunch: {},
    aiquoteAutoSelectAutomation: {},
    selectedAIQUOTEForLaunch: [{
        AIQUOTE_Site_URL__c: '',
    }],
    selectedAIQUOTE: [{
        Id: '',
        Name: '',
        AIQUOTE_Site_URL__c: '',
        Business_Email__c: '',
        ShippingStreet: '',
        ShippingCity: '',
        ShippingState: '',
        ShippingPostalCode: '',
        Business_Phone__c: '',
        Hours_Line_1__c: '',
        Hours_Line_2__c: '',
        Hours_Line_3__c: '',
        Hours_Line_4__c: '',
        Industry: '',
        Initial_Investment__c: '',
        coreSelect: ''
    }],
    selectedAIQUOTEAutomation: [{
        Id: '',
        Name: '',
        AIQUOTE_Site_URL__c: '',
        Business_Email__c: '',
        ShippingStreet: '',
        ShippingCity: '',
        ShippingState: '',
        ShippingPostalCode: '',
        Business_Phone__c: '',
        Hours_Line_1__c: '',
        Hours_Line_2__c: '',
        Hours_Line_3__c: '',
        Hours_Line_4__c: '',
        Industry: '',
        Initial_Investment__c: '',
        coreSelect: ''
    }],
    selectedAIQUOTEDetailRetrieved: false,
    selectedAIQUOTEDetailRetrievedForLaunch: false,
    aiquoteEmailRequests: [],
    aiquoteEmailRequested: false,
    selectedAIQUOTEError: {},
    deleteAIQUOTE: {},
    aiquoteAutomationStatus: {aiquote_automation:false}
  };

const aiquoteReducers = function(state = initialState,actions){
    switch(actions.type){
        case AIQUOTE_AUTOSELECT_SAVE_SUCCESS:
            return {...state, selectedAIQUOTEDetailRetrieved: false, aiquoteAutoSelect: {...actions.payload}}

        case AIQUOTE_AUTOSELECT_AUTOMATION_SAVE_SUCCESS:
            return {...state, selectedAIQUOTEDetailRetrieved: false, aiquoteAutoSelectAutomation: {...actions.payload}}




        case AIQUOTE_AUTOMATION_STATUS_SUCCESS:
            return {...state, selectedAIQUOTEDetailRetrieved: false, aiquoteAutomationStatus: {...actions.payload}}

        case AIQUOTE_AUTOMATION_STATUS_CHANGE_SUCCESS:
            return {...state, selectedAIQUOTEDetailRetrieved: false, aiquoteAutomationStatus: {...actions.payload}}



        // case AIQUOTE_AUTOSELECT_SAVE:
        //     return {...state, selectedAIQUOTEDetailRetrieved: false, aiquoteAutoSelect: [...actions.payload]}


        case AIQUOTE_AUTOSELECT_FOR_LAUNCH_SAVE:
            return {...state, selectedAIQUOTEDetailRetrievedForLaunch: true, aiquoteAutoSelectForLaunch: {...actions.payload}}

        case AIQUOTE_UPDATE_LOG_SUCCESS:
            return {...state, errors:[], aiquoteSites: [...actions.payload]};

        case AIQUOTE_STARTED_PAGE_SUCCESS:
            return {...state, successful: true, aiquoteSites:[...actions.payload]};
        case AIQUOTE_STARTED_PAGE_ERROR:
            return {...state, successful: false, errors:[...actions.error]};


        case AIQUOTE_EMAIL_REQUESTED_INIT:
            return {...state, errors:[], aiquoteEmailRequested:true};

        case AIQUOTE_CHECK_SALESFORCE_SUCCESS:
            return {...state, errors:[], aiquoteEmailRequested:false , aiquoteEmailRequests: [...actions.payload]};


        case AIQUOTE_CHECK_SERVER_SUCCESS:
            return {...state, errors:[], aiquoteEmailRequested:false , aiquoteSitesOnServer: [...actions.payload]};



        case AIQUOTE_AREA_LIST_SUCCESS:
            return {...state, errors:[], aiquoteSites: [...actions.payload]};

        case AIQUOTE_LIST_INIT:
            return {...state, errors:[], aiquotes: []};

        case AIQUOTE_LIST_SUCCESS:
            return {...state, successful: true, aiquotes:[...actions.payload]};
        case AIQUOTE_LIST_ERROR:
            return {...state, successful: false, errors:[...actions.error]};

            // case AIQUOTE_ACCOUNT_DETAIL_INIT:
        //     return {...state, errors:[], aiquotes: []};
        case AIQUOTE_ACCOUNT_DETAIL_SUCCESS:
            return {...state, selectedAIQUOTEDetailRetrieved: true, selectedAIQUOTE:[...actions.payload]};
        case AIQUOTE_ACCOUNT_DETAIL_ERROR:
            return {...state, selectedAIQUOTEDetailRetrieved: false, errors:[...actions.error]};

        case AIQUOTE_ACCOUNT_DETAIL_AUTOMATION_SUCCESS:
            return {...state, selectedAIQUOTEDetailRetrieved: true, selectedAIQUOTEAutomation:[...actions.payload]};
        case AIQUOTE_ACCOUNT_DETAIL_AUTOMATION_ERROR:
            return {...state, selectedAIQUOTEDetailRetrieved: false, errors:[...actions.error]};

        // case AIQUOTE_SITE_UPDATE_INIT:
        //     return {...state, errors:[], aiquotes: []};
        case AIQUOTE_SITE_UPDATE_SUCCESS:
            return {...state, successful: true, selectedAIQUOTE:[...actions.payload]};
        case AIQUOTE_SITE_UPDATE_ERROR:
            return {...state, successful: false, errors:[...actions.error]};


        //    User selected actions do not receive a case but is handled by saga
        // case AIQUOTE_SITE_STAGE_INIT:
        //     return {...state, errors:[], aiquotes: []};
        case AIQUOTE_SITE_STAGE_SUCCESS:
            return {...state, successful: true, aiquotes:[...actions.payload]};
        case AIQUOTE_SITE_STAGE_ERROR:
            return {...state, successful: false, errors:[...actions.error]};


        case AIQUOTE_DELETE_EMAIL_SUCCESS:
            return {...state, deleteAIQUOTE: {...actions.payload}};
        case AIQUOTE_DELETE_EMAIL_ERROR:
            return {...state, selectedAIQUOTEError:{...actions.error}};

        case AIQUOTE_DELETE_SUCCESS:
            return {...state, deleteAIQUOTE: {...actions.payload}};
        case AIQUOTE_DELETE_ERROR:
            return {...state, selectedAIQUOTEError:{...actions.error}};

        case AIQUOTE_START_SUCCESS:
            return {...state, startAIQUOTE: {...actions.payload}};
        case AIQUOTE_START_ERROR:
            return {...state, selectedAIQUOTEError:{...actions.error}};
        case RELEASE_STATE_DATA:
            return {...state, errors:[], aiquotes: [], selectedAIQUOTE: {},selectedAIQUOTEError: {},deleteAIQUOTE: {}}
        default:        
            return state;
    }
}

export default combineReducers({
    list_aiquote : aiquoteReducers,
    // manage_aiquote: manageAIQUOTEReducer,
    // aiquote_pad: aiquotePadReducer
});