import {put, all, call, takeLatest} from "redux-saga/effects";
import {
  AIQUOTE_LIST_INIT,
  AIQUOTE_SITE_UPDATE_INIT,
  AIQUOTE_SITE_STAGE_INIT,
  AIQUOTE_ACCOUNT_DETAIL_INIT,
  // AIQUOTE_ACCOUNT_DETAIL_SUCCESS,
  // AIQUOTE_ACCOUNT_DETAIL_ERROR,
  // AIQUOTE_DETAIL_INIT,
  // AIQUOTE_DELETE_INIT,
  // AIQUOTE_START_INIT,
  // aiquoteError,
  // aiquoteListSuccess,
  // aiquoteSiteUpdateSuccess,
  // aiquoteSiteStageSuccess,
  // aiquoteAccountDetailSuccess,
  // aiquoteDetailError,
  // aiquoteDetailSuccess,
  AIQUOTE_UPDATE_LOG,
  // aiquoteUpdateLogSuccess,
  AIQUOTE_AUTOSELECT_SAVE,
  // aiquoteAutoSelectSaveSuccess,
  AIQUOTE_AREA_LIST,
  // aiquoteAreaListSuccess,
  // aiquoteDeleteSuccess,
  AIQUOTE_DELETE_INIT,
  // aiquoteCheckSalesforceSuccess,
  AIQUOTE_CHECK_SALESFORCE_INIT,
  AIQUOTE_AUTOSELECT_AUTOMATION_SAVE,
  // aiquoteAutoSelectAutomationSaveSuccess,
  // aiquoteAccountDetailAutomationSuccess,
  AIQUOTE_ACCOUNT_DETAIL_AUTOMATION_INIT,
  AIQUOTE_DELETE_EMAIL_INIT,
  // aiquoteDeleteEmailSuccess,
  AIQUOTE_CHECK_SERVER_INIT,
  // aiquoteCheckServerSuccess,
  AIQUOTE_AUTOMATION_STATUS_INIT,
  AIQUOTE_AUTOMATION_STATUS_CHANGE_INIT, 
  // aiquoteAutomationStatusSuccess, 
  // aiquoteAutomationStatusChangeSuccess,
} from "./actions";

import { request } from '../../helpers/requests';
import { urls } from '../../helpers/urls';
// import manageAIQUOTESaga from './ManageAIQUOTE/saga';
import {browserRedirect, browserRedirectSamePage} from "../../helpers/helpers";


//AIQUOTE API calls

function aiquoteAutomationStatus() {
  return request('get', urls.AIQUOTE + '/aiquoteautomationstatus');
}

function aiquoteAutomationChangeStatus(payload) {
  return request('post', urls.AIQUOTE + '/aiquoteautomationchangestatus', payload);
}




function aiquoteCheckSalesforce(payload) {
  // return request('get', urls.AIQUOTE + '/aiquotewhmlist');
  return request('post', urls.AIQUOTE + '/aiquotechecksalesforce', payload);
}

function aiquoteCheckServerCall() {
  return request('get', urls.AIQUOTE + '/aiquotewhmlist');
  // return request('get', urls.AIQUOTE + '/aiquotecheckemail');
}

function aiquoteAreaListCall() {
  return request('get', urls.AIQUOTE + '/aiquotearealist');
}

function aiquoteListCall(payload) {
  // return request('get', urls.AIQUOTE + '/aiquotewhmlist', payload);
  return request('post', urls.AIQUOTE + '/aiquotesitelist', payload);
}

function aiquoteAccountDetailCall(payload) {
  return request('post', urls.AIQUOTE + '/aiquoteaccountdetail', payload);
}

function aiquoteAccountDetailAutomationCall(payload) {
  return request('post', urls.AIQUOTE + '/aiquoteaccountdetailautomation', payload);
}

function aiquoteSiteUpdateCall(payload) {
  return request('post', urls.AIQUOTE + '/aiquotesiteupdate', payload);
}

function aiquoteSiteStageCall(payload) {
  return request('post', urls.AIQUOTE + '/aiquotesitestage', payload);
}

function aiquoteUpdateLogCall() {
  return request('get', urls.AIQUOTE + '/updatelog');
}

function aiquoteDeleteCall(payload) {
  return request('delete', urls.AIQUOTE+'/deleteaiquote', payload);
}

function aiquoteDeleteEmailCall(payload) {
  return request('delete', urls.AIQUOTE+'/deleteemailaiquote', payload);
}
// function aiquoteDetailCall(id) {
//   return request('get', urls.AIQUOTE+'/'+id);
// }




// AIQUOTE Workers
function* aiquoteAutomationStatusWorker() {
  try {
    let response = yield call(aiquoteAutomationStatus);
    response = response.data.data;
    console.log(" aiquoteAutomationStatus response = ")
    console.log(response)
    // yield put(aiquoteAutomationStatusSuccess(response));
  } catch (err) {
    console.log('err in aiquoteAutomationStatusWorker in clientSite saga' + err)
    // yield put(aiquoteError(err.response.data.data));
  }
}

// AIQUOTE Workers
function* aiquoteAutomationStatusChangeWorker(payload) {
  try {
    let response = yield call(aiquoteAutomationChangeStatus, payload);
    response = response.data.data;
    console.log(" aiquoteAutomationStatusChangeWorker response = ")
    console.log(response)
    // yield put(aiquoteAutomationStatusChangeSuccess(response));
  } catch (err) {
    console.log('err in aiquoteAutomationStatusChangeWorker in clientSite saga' + err)
    // yield put(aiquoteError(err.response.data.data));
  }
}






// AIQUOTE Workers
function* aiquoteCheckSalesforceWorker(payload) {
  try {
    let response = yield call(aiquoteCheckSalesforce, payload);
    response = response.data.data;
    console.log(" aiquoteCheckSalesforceWorker response = ")
    console.log(response)
    // yield put(aiquoteCheckSalesforceSuccess(response));
  } catch (err) {
    console.log('err in aiquoteCheckSalesforceWorker in clientSite saga' + err)
    // yield put(aiquoteError(err.response.data.data));
  }
}



function* aiquoteCheckServerWorker() {
  try {
    let response = yield call(aiquoteCheckServerCall);
    response = response.data.data;
    console.log(" aiquoteCheckServerCall response = ")
    console.log(response)
    // yield put(aiquoteCheckServerSuccess(response));
  } catch (err) {
    console.log('err in aiquoteCheckSalesforceWorker in clientSite saga' + err)
    // yield put(aiquoteError(err.response.data.data));
  }
}


// AIQUOTE Workers
function* aiquoteListWorker(payload) {
  try {
    let response = yield call(aiquoteListCall, payload.payload);
    response = response.data.data;
    console.log(" aiquoteListWorker response = ")
    console.log(response)
    // yield put(aiquoteListSuccess(response));
  } catch (err) {
    console.log('err in clientSiteListWorker in clientSite saga' + err)
    // yield put(aiquoteError(err.response.data.data));
  }
}

// AIQUOTE Workers
function* aiquoteSiteUpdateWorker(payload) {
  try {
    let response = yield call(aiquoteSiteUpdateCall, payload.payload);
    response = response.data.data;
    console.log(" aiquoteListWorker response = ")
    console.log(response)
    // yield put(aiquoteSiteUpdateSuccess(response));
    yield call(browserRedirect,'/clients/aiquote');
  } catch (err) {
    console.log('err in clientSiteListWorker in clientSite saga' + err)
    // yield put(aiquoteError(err.response.data.data));
  }
}

// AIQUOTE Workers
function* aiquoteSiteStageWorker(payload) {
  try {
    let response = yield call(aiquoteSiteStageCall, payload.payload);
    response = response.data.data;
    // console.log(" aiquoteListWorker response = ")
    console.log(response)
    // yield put(aiquoteSiteStageSuccess(response));
    yield call(browserRedirect,'/clients/aiquote');
  } catch (err) {
    console.log('err in clientSiteListWorker in clientSite saga' + err)
    // yield put(aiquoteError(err.response.data.data));
  }
}

// AIQUOTE Workers
function* aiquoteUpdateLogWorker() {
  try {
    let response = yield call(aiquoteUpdateLogCall);
    response = response.data.data;
    console.log(" aiquoteUpdateLogCall response = ")
    console.log(response)
    // yield put(aiquoteUpdateLogSuccess(response));
  } catch (err) {
    // console.log('err in aiquoteUpdateLogSuccess in clientSite saga' + err)
    // yield put(aiquoteError(err.response.data.data));
  }
}

// AIQUOTE Workers
function* aiquoteAreaListWorker() {
  try {
    let response = yield call(aiquoteAreaListCall);
    response = response.data.data;
    console.log(" aiquoteAreaListCall response = ")
    console.log(response)
    // yield put(aiquoteAreaListSuccess(response));
  } catch (err) {
    // console.log('err in aiquoteAreaListSuccess in clientSite saga' + err)
    // yield put(aiquoteError(err.response.data.data));
  }
}

// AIQUOTE Workers
function* aiquoteAccountDetailWorker(payload) {
  try {
    let response = yield call(aiquoteAccountDetailCall, payload.payload);
    response = response.data.data;
    console.log(" aiquoteAccountDetailCall response = ")
    console.log(response)
    // yield put(aiquoteAccountDetailSuccess(response));
  } catch (err) {
    console.log('err in aiquoteAccountDetailWorker in aiquote saga' + err)
    // yield put(aiquoteError(err.response.data.data));
  }
}

// AIQUOTE Workers
function* aiquoteAccountDetailAutomationWorker(payload) {
  try {
    let response = yield call(aiquoteAccountDetailAutomationCall, payload.payload);
    response = response.data.data;
    console.log(" aiquoteAccountDetailAutomationCall response = ")
    console.log(response)
    // yield put(aiquoteAccountDetailAutomationSuccess(response));
  } catch (err) {
    console.log('err in aiquoteAccountDetailWorker in aiquote saga' + err)
    // yield put(aiquoteError(err.response.data.data));
  }
}

// AIQUOTE Selection
function* aiquoteAutoSelectSaveWorker(payload) {
  try {
    // console.log(payload.payload)
    // yield put(aiquoteAutoSelectSaveSuccess(payload.payload));
  } catch (err) {
    console.log('err in aiquoteAutoSelectSaveWorker in aiquote saga' + err)
  }
}


// AIQUOTE Workers
function* aiquoteAutoSelectAutomationSaveWorker(payload) {
  try {
    // let response = payload.payload;
    // response = response.data.data;
    console.log("aiquoteAutoSelectAutomationSaveWorker response = ")
    console.log(payload.payload)
    // yield put(aiquoteAutoSelectAutomationSaveSuccess(payload.payload));
  } catch (err) {
    console.log('err in aiquoteAutoSelectSaveWorker in aiquote saga' + err)
    // yield put(aiquoteError(err.response.data.data));
  }
}


function* aiquoteDeleteInitWorker({payload}) {
  try {
    console.log(`payload in delete saga`)
    console.log(payload)
    let response = yield call(aiquoteDeleteCall, payload);
    response = response.data;
    console.log(`aiquoteDeleteCall response:`)
    console.log(response)
    // yield put(aiquoteDeleteSuccess(response));
    /* success will have the launch page reload with the new results */
    // yield put(launchPageInit());
  } catch (err) {
    console.log(`error: ${err.message}`);
    // yield put(launchStartError(err.response.data));
  }
}



function* aiquoteDeleteEmailInitWorker({payload}) {
  try {
    console.log(`payload in delete saga`)
    console.log(payload)
    let response = yield call(aiquoteDeleteEmailCall, payload);
    response = response.data;
    console.log(`aiquoteDeleteCall response:`)
    console.log(response)
    // yield put(aiquoteDeleteEmailSuccess(response));
    /* success will have the launch page reload with the new results */
    // yield put(launchPageInit());
  } catch (err) {
    console.log(`error: ${err.message}`);
    // yield put(launchStartError(err.response.data));
  }
}



// AIQUOTE Watcher
export default function* aiquoteSaga() {
  yield all([
    takeLatest(AIQUOTE_AUTOMATION_STATUS_INIT, aiquoteAutomationStatusWorker),
    takeLatest(AIQUOTE_AUTOMATION_STATUS_CHANGE_INIT, aiquoteAutomationStatusChangeWorker),


    takeLatest(AIQUOTE_AUTOSELECT_SAVE, aiquoteAutoSelectSaveWorker),
    takeLatest(AIQUOTE_AUTOSELECT_AUTOMATION_SAVE, aiquoteAutoSelectAutomationSaveWorker),
    takeLatest(AIQUOTE_CHECK_SALESFORCE_INIT, aiquoteCheckSalesforceWorker),
    takeLatest(AIQUOTE_CHECK_SERVER_INIT, aiquoteCheckServerWorker),
    takeLatest(AIQUOTE_LIST_INIT, aiquoteListWorker),
    takeLatest(AIQUOTE_AREA_LIST, aiquoteAreaListWorker),
    takeLatest(AIQUOTE_SITE_UPDATE_INIT, aiquoteSiteUpdateWorker),
    takeLatest(AIQUOTE_SITE_STAGE_INIT, aiquoteSiteStageWorker),
    takeLatest(AIQUOTE_ACCOUNT_DETAIL_AUTOMATION_INIT, aiquoteAccountDetailAutomationWorker),
    takeLatest(AIQUOTE_ACCOUNT_DETAIL_INIT, aiquoteAccountDetailWorker),
    takeLatest(AIQUOTE_UPDATE_LOG, aiquoteUpdateLogWorker),
    takeLatest(AIQUOTE_DELETE_INIT, aiquoteDeleteInitWorker),
    takeLatest(AIQUOTE_DELETE_EMAIL_INIT, aiquoteDeleteEmailInitWorker),
    // takeLatest(AIQUOTE_START_INIT, aiquoteStartWorker),
    // takeEvery(AIQUOTE_START_REQUESTING, aiquoteStartWorker),
    // manageAIQUOTESaga()
  ]);
}