export const AIQUOTE_AREA_LIST = 'AIQUOTE_AREA_LIST';
export const AIQUOTE_AREA_LIST_SUCCESS = 'AIQUOTE_AREA_LIST_SUCCESS';

export const AIQUOTE_UPDATE_LOG = 'AIQUOTE_UPDATE_LOG';
export const AIQUOTE_UPDATE_LOG_SUCCESS = 'AIQUOTE_UPDATE_LOG_SUCCESS';


// Page that shows the progress of AIQUOTEs
export const AIQUOTE_STARTED_PAGE_INIT = 'AIQUOTE_STARTED_PAGE_INIT';
export const AIQUOTE_STARTED_PAGE_SUCCESS = 'AIQUOTE_STARTED_PAGE_SUCCESS';
export const AIQUOTE_STARTED_PAGE_ERROR = 'AIQUOTE_STARTED_PAGE_ERROR';



export const AIQUOTE_PAGE_INIT = 'AIQUOTE_PAGE_INIT';
export const AIQUOTE_PAGE_ERROR = 'AIQUOTE_PAGE_ERROR';
export const AIQUOTE_PAGE_SUCCESS = 'AIQUOTE_PAGE_SUCCESS';


export const AIQUOTE_LIST_INIT = 'AIQUOTE_LIST_INIT';
export const AIQUOTE_LIST_ERROR = 'AIQUOTE_LIST_ERROR';
export const AIQUOTE_LIST_SUCCESS = 'AIQUOTE_LIST_SUCCESS';


export const AIQUOTE_SITE_UPDATE_INIT = 'AIQUOTE_SITE_UPDATE_INIT';
export const AIQUOTE_SITE_UPDATE_ERROR = 'AIQUOTE_SITE_UPDATE_ERROR';
export const AIQUOTE_SITE_UPDATE_SUCCESS = 'AIQUOTE_SITE_UPDATE_SUCCESS';


export const AIQUOTE_SITE_STAGE_INIT = 'AIQUOTE_SITE_STAGE_INIT';
export const AIQUOTE_SITE_STAGE_SUCCESS = 'AIQUOTE_SITE_STAGE_SUCCESS';
export const AIQUOTE_SITE_STAGE_ERROR = 'AIQUOTE_SITE_STAGE_ERROR';


export const AIQUOTE_ACCOUNT_DETAIL_INIT = 'AIQUOTE_ACCOUNT_DETAIL_INIT';
export const AIQUOTE_ACCOUNT_DETAIL_SUCCESS = 'AIQUOTE_ACCOUNT_DETAIL_SUCCESS';
export const AIQUOTE_ACCOUNT_DETAIL_ERROR = 'AIQUOTE_ACCOUNT_DETAIL_ERROR';


export const AIQUOTE_ACCOUNT_DETAIL_AUTOMATION_INIT = 'AIQUOTE_ACCOUNT_DETAIL_AUTOMATION_INIT';
export const AIQUOTE_ACCOUNT_DETAIL_AUTOMATION_SUCCESS = 'AIQUOTE_ACCOUNT_DETAIL_AUTOMATION_SUCCESS';
export const AIQUOTE_ACCOUNT_DETAIL_AUTOMATION_ERROR = 'AIQUOTE_ACCOUNT_DETAIL_AUTOMATION_ERROR';


export const AIQUOTE_DETAIL_INIT = 'AIQUOTE_DETAIL_INIT';
export const AIQUOTE_DETAIL_ERROR = 'AIQUOTE_DETAIL_ERROR';
export const AIQUOTE_DETAIL_SUCCESS = 'AIQUOTE_DETAIL_SUCCESS';
export const AIQUOTE_DETAIL_CLOSE = 'AIQUOTE_DETAIL_CLOSE';

export const AIQUOTE_DELETE_INIT = 'AIQUOTE_DELETE_INIT';
export const AIQUOTE_DELETE_ERROR = 'AIQUOTE_DELETE_ERROR';
export const AIQUOTE_DELETE_SUCCESS = 'AIQUOTE_DELETE_SUCCESS';


export const AIQUOTE_DELETE_EMAIL_INIT = 'AIQUOTE_DELETE_EMAIL_INIT';
export const AIQUOTE_DELETE_EMAIL_ERROR = 'AIQUOTE_DELETE_EMAIL_ERROR';
export const AIQUOTE_DELETE_EMAIL_SUCCESS = 'AIQUOTE_DELETE_EMAIL_SUCCESS';



export const RELEASE_STATE_DATA = 'RELEASE_STATE_DATA';

export const AIQUOTE_START_INIT = 'AIQUOTE_START_INIT';
export const AIQUOTE_START_REQUESTING = 'AIQUOTE_START_REQUESTING';
export const AIQUOTE_START_SUCCESS = 'AIQUOTE_START_SUCCESS';
export const AIQUOTE_START_ERROR = 'AIQUOTE_START_ERROR';
// export const AIQUOTE_START_PAGE_INIT = 'AIQUOTE_START_PAGE_INIT';

export const AIQUOTE_AUTOSELECT_AUTOMATION_SAVE = 'AIQUOTE_AUTOSELECT_AUTOMATION_SAVE';
export const AIQUOTE_AUTOSELECT_AUTOMATION_SAVE_SUCCESS = 'AIQUOTE_AUTOSELECT_AUTOMATION_SAVE_SUCCESS';


export const AIQUOTE_AUTOSELECT_SAVE = 'AIQUOTE_AUTOSELECT_SAVE';
export const AIQUOTE_AUTOSELECT_SAVE_SUCCESS = 'AIQUOTE_AUTOSELECT_SAVE_SUCCESS';

export const AIQUOTE_AUTOSELECT_FOR_LAUNCH_SAVE = 'AIQUOTE_AUTOSELECT_FOR_LAUNCH_SAVE';
export const AIQUOTE_AUTOSELECT_FOR_LAUNCH_SAVE_SUCCESS = 'AIQUOTE_AUTOSELECT_FOR_LAUNCH_SAVE_SUCCESS';

export const AIQUOTE_EMAIL_REQUESTED_INIT = 'AIQUOTE_EMAIL_REQUESTED_INIT';
// export const AIQUOTE_EMAIL_REQUESTED_SUCCESSFUL = 'AIQUOTE_EMAIL_REQUESTED_SUCCESSFUL';


export const AIQUOTE_CHECK_SALESFORCE_INIT = 'AIQUOTE_CHECK_SALESFORCE_INIT';
export const AIQUOTE_CHECK_SALESFORCE_SUCCESS = 'AIQUOTE_CHECK_SALESFORCE_SUCCESS';
export const AIQUOTE_CHECK_EMAIL_ERROR = 'AIQUOTE_CHECK_EMAIL_ERROR';

export const AIQUOTE_CHECK_EMAIL_INIT = 'AIQUOTE_CHECK_EMAIL_INIT';


export const AIQUOTE_CHECK_SERVER_INIT = 'AIQUOTE_CHECK_SERVER_INIT';
export const AIQUOTE_CHECK_SERVER_SUCCESS = 'AIQUOTE_CHECK_SERVER_SUCCESS';
export const AIQUOTE_CHECK_SERVER_ERROR = 'AIQUOTE_CHECK_SERVER_ERROR';

//

/* Get status of automation being on or off */
export const AIQUOTE_AUTOMATION_STATUS_INIT = 'AIQUOTE_AUTOMATION_STATUS_INIT';
export const AIQUOTE_AUTOMATION_STATUS_SUCCESS = 'AIQUOTE_AUTOMATION_STATUS_SUCCESS';
export const AIQUOTE_AUTOMATION_STATUS_ERROR = 'AIQUOTE_AUTOMATION_STATUS_ERROR';


/* Change status of automation being on or off */
export const AIQUOTE_AUTOMATION_STATUS_CHANGE_INIT = 'AIQUOTE_AUTOMATION_STATUS_CHANGE_INIT';
export const AIQUOTE_AUTOMATION_STATUS_CHANGE_SUCCESS = 'AIQUOTE_AUTOMATION_STATUS_CHANGE_SUCCESS';
export const AIQUOTE_AUTOMATION_STATUS_CHANGE_ERROR = 'AIQUOTE_AUTOMATION_STATUS_CHANGE_ERROR';



export function AIQUOTEEmailRequestedInit() {
    return {
        type: AIQUOTE_EMAIL_REQUESTED_INIT
    };
}

export function AIQUOTECheckSalesforceInit(payload) {
    return {
        type: AIQUOTE_CHECK_SALESFORCE_INIT,
        payload
    };
}

export function AIQUOTECheckEmailInit() {
    return {
        type: AIQUOTE_CHECK_EMAIL_INIT
    };
}


export function AIQUOTECheckServerInit() {
    return {
        type: AIQUOTE_CHECK_SERVER_INIT
    };
}


// export function AIQUOTECheckSalesforceSuccess(payload) {
//     return {
//         type: AIQUOTE_CHECK_SALESFORCE_SUCCESS,
//         payload
//     };
// }


export function AIQUOTECheckServerSuccess(payload) {
    return {
        type: AIQUOTE_CHECK_SERVER_SUCCESS,
        payload
    };
}


export function AIQUOTECheckServerError(payload) {
    return {
        type: AIQUOTE_CHECK_SERVER_ERROR,
        payload
    };
}




export function AIQUOTEAutoSelectAutomationSave(payload) {
    return {
        type: AIQUOTE_AUTOSELECT_AUTOMATION_SAVE,
        payload
    };
}


export function AIQUOTEAutoSelectAutomationSaveSuccess(payload) {
    return {
        type: AIQUOTE_AUTOSELECT_AUTOMATION_SAVE_SUCCESS,
        payload
    };
}




export function AIQUOTEAutoSelectSave(payload) {
    return {
        type: AIQUOTE_AUTOSELECT_SAVE,
        payload
    };
}


export function AIQUOTEAutoSelectSaveSuccess(payload) {
    return {
        type: AIQUOTE_AUTOSELECT_SAVE_SUCCESS,
        payload
    };
}






export function AIQUOTEAutoSelectForLaunchSave(payload) {
    return {
        type: AIQUOTE_AUTOSELECT_FOR_LAUNCH_SAVE,
        payload
    };
}


export function AIQUOTEAutoSelectForLaunchSaveSuccess(payload) {
    return {
        type: AIQUOTE_AUTOSELECT_FOR_LAUNCH_SAVE_SUCCESS,
        payload
    };
}







export function AIQUOTEStartedPageInit(payload) {
    return {
        type: AIQUOTE_STARTED_PAGE_INIT,
        payload
    };
}

export function AIQUOTEPageInit(payload) {
    return {
        type: AIQUOTE_PAGE_INIT,
        payload
    };
}

export function AIQUOTEAreaList() {
    return {
        type: AIQUOTE_AREA_LIST
    };
}

export function AIQUOTEAreaListSuccess(payload) {
    return {
        type: AIQUOTE_AREA_LIST_SUCCESS,
        payload
    };
}



export function AIQUOTEUpdateLog(payload) {
    return {
        type: AIQUOTE_UPDATE_LOG,
        payload
    };
}

export function AIQUOTEUpdateLogSuccess(payload) {
    return {
        type: AIQUOTE_UPDATE_LOG_SUCCESS,
        payload
    };
}


// Stage a site account list for autocomplete
export function AIQUOTEListInit(payload) {
    return {
        type: AIQUOTE_LIST_INIT,
        payload
    };
}

export function AIQUOTEListError(error) {
    return {
        type: AIQUOTE_LIST_ERROR,
        error,
    };
}

export function AIQUOTEListSuccess(payload) {
    return {
        type: AIQUOTE_LIST_SUCCESS,
        payload
    };
}



export function AIQUOTESiteUpdateInit(payload) {
    return {
        type: AIQUOTE_SITE_UPDATE_INIT,
        payload
    };
}

export function AIQUOTESiteUpdateError(error) {
    return {
        type: AIQUOTE_SITE_UPDATE_ERROR,
        error,
    };
}

export function AIQUOTESiteUpdateSuccess(payload) {
    return {
        type: AIQUOTE_SITE_UPDATE_SUCCESS,
        payload
    };
}



/* Get status of  being on or off */
export function AIQUOTEAutomationStatusInit() {
    return {
        type: AIQUOTE_AUTOMATION_STATUS_INIT,
    };
}

export function AIQUOTEAutomationStatusSuccess(payload) {
    return {
        type: AIQUOTE_AUTOMATION_STATUS_SUCCESS,
        payload
    };
}




/* Change status of automation being on or off */
export function AIQUOTEAutomationStatusChangeInit(payload) {
    return {
        type: AIQUOTE_AUTOMATION_STATUS_CHANGE_INIT,
        payload
    };
}


export function AIQUOTEAutomationStatusChangeSuccess(payload) {
    return {
        type: AIQUOTE_AUTOMATION_STATUS_CHANGE_SUCCESS,
        payload
    };
}






export function AIQUOTEAccountDetailAutomationInit(payload) {
    return {
        type: AIQUOTE_ACCOUNT_DETAIL_AUTOMATION_INIT,
        payload
    };
}





export function AIQUOTEAccountDetailAutomationSuccess(payload) {
    return {
        type: AIQUOTE_ACCOUNT_DETAIL_AUTOMATION_SUCCESS,
        payload
    };
}

export function AIQUOTEAccountDetailAutomationError(error) {
    return {
        type: AIQUOTE_ACCOUNT_DETAIL_AUTOMATION_ERROR,
        error,
    };
}







export function AIQUOTEAccountDetailInit(payload) {
    return {
        type: AIQUOTE_ACCOUNT_DETAIL_INIT,
        payload
    };
}

export function AIQUOTEAccountDetailSuccess(payload) {
    return {
        type: AIQUOTE_ACCOUNT_DETAIL_SUCCESS,
        payload
    };
}

export function AIQUOTEAccountDetailError(error) {
    return {
        type: AIQUOTE_ACCOUNT_DETAIL_ERROR,
        error,
    };
}







export function AIQUOTESiteStageInit(payload) {
    return {
        type: AIQUOTE_SITE_STAGE_INIT,
        payload
    };
}

export function AIQUOTESiteStageError(error) {
    return {
        type: AIQUOTE_SITE_STAGE_ERROR,
        error,
    };
}

export function AIQUOTESiteStageSuccess(payload) {
    return {
        type: AIQUOTE_SITE_STAGE_SUCCESS,
        payload
    };
}









export function AIQUOTEDetailInit(id) {
    return {
        type: AIQUOTE_DETAIL_INIT,
        id 
    };
}

export function AIQUOTEDetailError(error) {
    return {
        type: AIQUOTE_DETAIL_ERROR,
        error,
    };
}

export function AIQUOTEDetailSuccess(payload) {
    return {
        type: AIQUOTE_DETAIL_SUCCESS,
        payload
    };
}

export function AIQUOTEDetailClose() {
    return {
        type: AIQUOTE_DETAIL_CLOSE,
    };
}

export function AIQUOTEDeleteInit(payload) {
    return {
        type: AIQUOTE_DELETE_INIT,
        payload
    };
}

export function AIQUOTEDeleteEmailInit(payload) {
    return {
        type: AIQUOTE_DELETE_EMAIL_INIT,
        payload
    };
}

// export function AIQUOTEStartInit(id) {
//     return {
//         type: AIQUOTE_START_INIT,
//         id
//     };
// }

export function AIQUOTEStartError(error) {
    return {
        type: AIQUOTE_START_ERROR,
        error,
    };
}

export function AIQUOTEStartSuccess(payload) {
    return {
        type: AIQUOTE_START_SUCCESS,
        payload
    };
}

// export function AIQUOTEStartRequest(payload,id) {
//     return {
//         type: AIQUOTE_START_REQUESTING,
//         payload,
//         id
//     };
// }

export function AIQUOTEDeleteEmailError(error) {
    return {
        type: AIQUOTE_DELETE_EMAIL_ERROR,
        error,
    };
}

export function AIQUOTEDeleteEmailSuccess(payload) {
    return {
        type: AIQUOTE_DELETE_EMAIL_SUCCESS,
        payload
    };
}

export function AIQUOTEDeleteError(error) {
    return {
        type: AIQUOTE_DELETE_ERROR,
        error,
    };
}

export function AIQUOTEDeleteSuccess(payload) {
    return {
        type: AIQUOTE_DELETE_SUCCESS,
        payload
    };
}


export function releaseStateData(){
    return {
        type: RELEASE_STATE_DATA,
    }
}