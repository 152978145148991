import React, { memo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import {CircularProgress, Grid} from "@mui/material";
import PrettyPrintJson from "../../helpers/PrettyPrintJson";
import { salesForceUserTokens } from "../../helpers/helpers";
import {stagingAccountDetailInit, stagingAutoSelectSave, stagingListInit} from "./actions";


export default function AutocompleteSelection(props) {
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const loading = open && options.length === 0;

  const dispatch = useDispatch();
  const saveStagingsAccountSelected = (payload) => dispatch(stagingAutoSelectSave(payload));
  const getStagingsAccountList = (payload) => dispatch(stagingListInit(payload));

  const stagingAccountNames = useSelector((state) => state.stagings.list_staging.stagings);

  // Debounce function to limit the rate of API calls
  const debounce = (func, delay) => {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  // Trigger getStagingsAccountList when user types with debounce
  const debouncedGetStagingsAccountList = debounce(() => {
    getStagingsAccountList({
      userTokens: salesForceUserTokens(),
    });
  }, 300);

  useEffect(() => {
    if (inputValue) {
      debouncedGetStagingsAccountList();
    }
  }, [inputValue]);

  const handleAutocompleteSelectionChange = (valueSelected) => {
    saveStagingsAccountSelected(valueSelected);
  };

  useEffect(() => {
    let active = true;
    if (!loading) {
      return undefined;
    }

    (async () => {
      if (active) {
        setOptions([...stagingAccountNames]);
        console.log(`Staging Account Names length: `, stagingAccountNames.length);
      }
    })();

    return () => {
      active = false;
    };
  }, [loading]);

  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);

  return (
    <Autocomplete
      open={open}
      onOpen={() => {
        setOpen(true);
      }}
      onClose={() => {
        setOpen(false);
      }}
      loading={loading}
      disablePortal
      getOptionLabel={(stagings) => stagings.Name}
      onChange={(event, newValue) => {
        handleAutocompleteSelectionChange(newValue);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      id="controllable-autocomplete"
      options={stagingAccountNames}
      getOptionSelected={(option, value) => option.Id === value.Id}
      renderOption={(props, option) => (
        <li {...props} key={option.Id}>
          {option.Name}
        </li>
      )}
      renderInput={(params) => (
        <TextField
          sx={{ minWidth: "500px", width: "100%" }}
          {...params}
          label="Account Name"
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
    />
  );
}

